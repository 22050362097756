import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormInput from '../Form/FormInput';
import FormButton from '../Form/FormButton';
import { saveDict } from '../../actions/DictActions';
import { connect } from 'react-redux'

class RecognitionTypeItem extends React.Component {
  state = {
    edit: false,
    value: this.props.item.name
  }
  renderNormal() {
    return (<div className="ListItem">
      {this.state.value}
      <FontAwesomeIcon className="IconLink" icon="pencil-alt" onClick={() => {
        this.setState(Object.assign(this.state, { edit: true }))
      }} />
    </div>
    )
  }

  renderEdit() {
    return (
      <div className="ListItem FullForm">
        <FormInput value={this.state.value} onChange={(event) => {
          this.setState(Object.assign({}, this.state, { edit: true, value: event.target.value }))
        }} />
        <FormButton title={<FontAwesomeIcon icon="save" />} onClick={() => {
          this.setState(Object.assign({}, this.state, { edit: false }))
          this.props.saveDict("recognition-types", { id: this.props.item.id, name: this.state.value })
        }} />
      </div>
    )
  }

  render() {
    if (this.state.edit)
      return this.renderEdit()
    return this.renderNormal()

  }
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, { saveDict })(RecognitionTypeItem)
