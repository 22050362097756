import React from 'react';

class FormLabel extends React.Component {
  render() {
    return (
      <div className="FormLabel">{this.props.text}</div>
    );
  }
}

export default FormLabel;