import React, { Component } from 'react';
import Header from '../../components/Header';
import { connect } from 'react-redux';
import { fetchReport, fetchReportSilently } from '../../actions/ReportActions';
import ReportItem from '../../components/items/ReportItem';
import Loading from '../../components/Loading';
import ReportResultItem from '../../components/ReportResultItem';
import { dataDelete } from '../../actions/DataActions';

class Report extends Component {
  constructor(props) {
    super(props);

    this.props.fetchReport(props.match.params.id)
    setInterval(() => {
      this.props.fetchReportSilently(props.match.params.id)
    }, 5000);
  }

  deleteItem = (id) => {
    this.props.dataDelete(id)
  }

  renderDownloadButton() {
    if (this.props.report.result1)
      return <a className="blue" href={`${window.location.origin.replace('//', '//api.')}/report/${this.props.report.id}/pdf`}>Download PDF</a>
  }

  set1Title = () => {
    if (this.props.report.old_type1 != null)
      return this.props.report.old_type1.name
    return this.props.report.set1.map((item) => item.type.name).join(', ')
  }

  set2Title = () => {
    if (this.props.report.old_type2 != null)
      return this.props.report.old_type2.name
    return this.props.report.set2.map((item) => item.type.name).join(', ')
  }

  render() {
    if (!this.props.report) 
      return <Loading />
    return (
      <div>
        <Header />
        <div className="TopBar">
          <h1>Report</h1>
          {this.renderDownloadButton()}
        </div>

        <div className="DataList ReportList">
          <div className="ListHeader">
            <div>Version</div>
            <div>Data payload</div>
            <div>Type 1</div>
            <div>Type 2</div>
            <div></div>
            <div>Generate at</div>
          </div>
          <ReportItem item={this.props.report} />
        </div>
        <div className="ReportResultHeader">
          <div>{this.set1Title()}</div>
          <div>{this.set2Title()}</div>
        </div>
        <div className="ReportSplit">
          <div>
            {this.props.report.set1.map((config, configIndex) => 
              config.items.map((item, index) => <ReportResultItem item={item} key={index} onDeleteItem={this.props.user.is_editor ? this.deleteItem : null} />)
            )}
          </div>
          <div className="ReportSplitLine" />
          <div>
            {this.props.report.set2.map((config, configIndex) => 
              config.items.map((item, index) => <ReportResultItem item={item} key={index} onDeleteItem={this.props.user.is_editor ? this.deleteItem : null} />)
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { report } = state.report
  const { user } = state.main
  return { report, user }
}

export default connect(mapStateToProps, { fetchReport, fetchReportSilently, dataDelete})(Report);