import { combineReducers } from "redux";
import MainReducer from './MainReducer';
import AuthReducer from "./AuthReducer";
import DataReducer from "./DataReducer";
import ReportReducer from "./ReportReducer";
import ReportGeneratorReducer from "./ReportGeneratorReducer";

export default combineReducers({
  main: MainReducer,
  auth: AuthReducer,
  data: DataReducer,
  report: ReportReducer,
  reportGenerator: ReportGeneratorReducer
});