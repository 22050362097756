import React from 'react';
import UploadImage from '../UploadImage';
import FormInput from './FormInput';
import FormSelect from './FormSelect';
import FormButton from './FormButton';
import { connect } from 'react-redux';
import { sendData, sendFile, imageCrop, updateImageUrl } from '../../actions/DataActions';
import { loadImage } from '../../helpers';
import { URL, headers } from '../../actions/API';

class AddDataForm extends React.Component {
  INIT_STATE = {
    source: '',
    type: this.props.recognition_types[0].id,
    error: null
  }
  state = { ...this.INIT_STATE }

  onSend = () => {
    if (!this.props.filename || !this.state.source || !this.state.type) {
      this.setState(Object.assign(this.state, { error: 'Fill all fields.' }))
      return
    }

    const json = {
      'source': this.state.source,
      'type': this.state.type,
      'file': this.props.filename
    }
    this.setState({ source: "", type: "", error: null })
    this.props.sendData(json).then((success) => {
      if (success) {
        this.setState({ ...this.INIT_STATE })
      }
    })
  }

  onChangeSource = (event) => {
    this.setState(Object.assign(this.state, { source: event.target.value, error: null }));
  }

  onChangeType = (event) => {
    this.setState(Object.assign(this.state, { type: event.target.value, error: null }));
  }

  renderErrors = () => {
    if (this.state.error) {
      return <div className="FormError">{this.state.error}</div>
    }
    if (this.props.error === 'not_square') {
      return <div className="FormError">Image has to be square (equal width and height).</div>
    }
    if (this.props.error === 'too_small') {
      return <div className="FormError">Image has too small dimensions. Minimum is 512px.</div>
    }
  }

  onImageFromUrl = event => {
    let reader = new FileReader();
    let file = event.target.value;

    if (file === undefined)
      return

    if (file.match(/^(https?:\/\/).*\.(jpeg|jpg|png)$/) === null) {
      return
    }

    const request = new Request(URL + "image_url", {
      method: 'POST',
      body: JSON.stringify({url: file}),
      headers: headers()
    })

    fetch(request)
      .then(response => response.blob())
      .then((response) => {
        reader.onloadend = () => {
          loadImage(reader.result, this.props.sendFile, this.props.imageCrop)
        }
        reader.readAsDataURL(response)
      })

  }

  render() {
    if (!this.props.user.is_editor)
        return <br/>
    return (
      <div className="Form">
        Add new data to system
        {this.renderErrors()}
        <div className="FormContent">
          <UploadImage file={this.props.filename} />
          <div className="FormContentInputs">
            <FormInput hint="Source of image" value={this.state.source} onChange={this.onChangeSource} />
            <FormSelect data={this.props.recognition_types} onChange={this.onChangeType} value={this.state.type} />
            <div className="FormContentBottom">
              <FormInput hint="Image from url (Optional)" onChange={this.onImageFromUrl} value={this.props.imageFromUrl} />
              <FormButton title="Add" onClick={this.onSend} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const { filename, error, imageFromUrl } = state.data
  const { recognition_types, user } = state.main
  return { filename, error, imageFromUrl, recognition_types, user }
}

export default connect(mapStateToProps, { sendData, sendFile, imageCrop, updateImageUrl })(AddDataForm);