import { API_LOGIN_SUCCESS, API_LOGIN_ERROR } from "../actions/types";

const INITIAL_STATE = {
  loggedIn: false,
  error: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case API_LOGIN_SUCCESS:
      return { loggedIn: true, error: null }
    case API_LOGIN_ERROR:
      return { loggedIn: false, error: action.message }
    default:
      return state
  }
}