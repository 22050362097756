import { API_DATA_FILE_UPLOADED, API_DATA_FILE_ERROR, API_DATA_ERROR, API_DATA_SUCCESS, IMAGE_CROPPED, IMAGE_CROP, IMAGE_FROM_URL, API_GET_DATA_ITEM_SUCCESS, API_DATA_ITEM_RESULTS_SUCCESS } from "../actions/types";

const INITIAL_STATE = {
  filename: false,
  error: null,
  imageCrop: null,
  imageFromUrl: "",
  pages: 1,
  page: 1,
  currentItem: null,
  currentItemResults: [],
}


export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case API_DATA_FILE_UPLOADED:
      return Object.assign({}, state, { filename: action.file, error: null, imageCrop: null })
    case API_DATA_FILE_ERROR:
      return Object.assign({}, state, { filename: null, error: action.error })
    case API_DATA_ERROR:
      return Object.assign({}, state, { error: action.error })
    case API_DATA_SUCCESS:
      return Object.assign({}, state, { filename: null, error: null, imageFromUrl: "" })
    case API_GET_DATA_ITEM_SUCCESS:
      return Object.assign({}, state, { currentItem: action.data.item })
    case API_DATA_ITEM_RESULTS_SUCCESS:
      return Object.assign({}, state, { currentItemResults: action.data.results})
    case IMAGE_CROP:
      return Object.assign({}, state, { imageCrop: action.image })
    case IMAGE_CROPPED:
      return Object.assign({}, state, { pixelCrop: action.pixelCrop })
    case IMAGE_FROM_URL:
      return Object.assign({}, state, { imageFromUrl: action.url })
    default:
      return state
  }
}