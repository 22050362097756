import React from 'react';

class FormGeneratorSets extends React.Component {
  render() {
    var disabledItems = Object.values(this.props.disabledItems)
    var checkedItems = Object.values(this.props.value)

    const checkboxProps = {
      onChange: this.props.onChange,
      set: this.props.set
    }

    return (
        <div className="GenerateRaportSet" >
        {this.props.data.map(function (object, i) {
          let disabled = disabledItems.indexOf(object.id) >= 0
          let checked = checkedItems.indexOf(object.id) >= 0
        return <label key={i} className={ disabled ? "disabled" : ""}><input type="checkbox" disabled={disabled} value={object.id} checked={checked} {...checkboxProps} />{object.name} - {object.count}</label>;
        })}
    </div>
    );
  }
}

export default FormGeneratorSets;