import React, { Component } from 'react';
import Header from '../components/Header';
import LinkBox from '../components/LinkBox';

class Admin extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="LinkBoxList">
          <LinkBox title="Recognition types" path="/admin/recognition_types" />
        </div>
      </div>
    );
  }
}

export default Admin;