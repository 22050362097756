import { API_START, API_END, API_AUTCH_CHECK_OK, API_AUTCH_CHECK_ERROR, API_DATA_SUCCESS, API_GET_DATA_SUCCESS, API_ERROR, API_DICT_ADD, API_DATA_UPDATE_SUCCESS, API_DATA_DELETE_SUCCESS, FILTER_SELECTED } from "../actions/types";

const INITIAL_STATE = {
  loading: false,
  loggedin: null,
  filter: 0,
  user: null,
  apiError: false,
  data: [],
  page: 1,
  pages: 1,
  counts: []
}


export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case API_START:
      return Object.assign({}, state, { loading: true, apiError: false })
    case API_END:
      return Object.assign({}, state, { loading: false })
    case API_ERROR:
      return Object.assign({}, state, { apiError: true })
    case API_AUTCH_CHECK_OK:
      return Object.assign({}, state, { loggedin: true, ...action.data })
    case API_AUTCH_CHECK_ERROR:
      return Object.assign({}, state, { loggedin: false })
    case API_DATA_SUCCESS:
      return Object.assign({}, state, { data: [action.data, ...state.data] })
    case API_DATA_UPDATE_SUCCESS:
      return Object.assign({}, state, { data: state.data.map((item) => item.id === action.data.id ? action.data : item) })
    case FILTER_SELECTED:
      console.log(action);
      return Object.assign({}, state, { filter: action.filter })
    case API_GET_DATA_SUCCESS:
      return Object.assign({}, state, { 
        data: action.data.items,
        page: parseInt(action.data.page),
        pages: parseInt(action.data.pages),
        counts: action.data.counts
      })
    case API_DATA_DELETE_SUCCESS:
      return Object.assign({}, state, { data: state.data.filter((item) => item.id !== action.id ) })
    case API_DICT_ADD:
      switch (action.data.dict) {
        case 'recognition_types':
          return Object.assign({}, state, { recognition_types: [...state.recognition_types, action.data.item] })
          default:
          break;
      }
      break;
    default:
      return state
  }
}