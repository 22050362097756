import React from 'react';
import { saveDict } from '../../actions/DictActions';
import { connect } from 'react-redux'
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

class ReportItem extends React.Component {

  set1Title = () => {
    if (this.props.item.old_type1 != null)
      return this.props.item.old_type1.name
    return this.props.item.set1.map((item) => item.type.name).join(', ')
  }

  set2Title = () => {
    if (this.props.item.old_type2 != null)
      return this.props.item.old_type2.name
    return this.props.item.set2.map((item) => item.type.name).join(', ')
  }

  render() {
    console.log(this.props.item);
    return (
      <div className="ListItem" onClick={() => {window.location = '/report/' + this.props.item.id}}>
        <div>{this.props.item.version}</div>
        <div>{this.props.item.count}</div>
        <div>{this.set1Title()}<br />
          <strong>
            {this.props.item.result1 ? this.props.item.result1 + "%" : "n/a"}
          </strong>
        </div>
          <div>{this.set2Title()}<br />
          <strong>
            {this.props.item.result2 ? this.props.item.result2 + "%" : "n/a"}
          </strong>
        </div>
        <div><CircularProgressbar
          className="Proggress"
          percentage={this.props.item.progress}
          text={`${this.props.item.progress}%`}
        /></div>
        <div>{this.props.item.created_at}</div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, { saveDict })(ReportItem)
