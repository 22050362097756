import { getPixelCrop } from 'react-image-crop';

export const getImage = (image) => {
  const crop = { x: 0, y: 0, width: image.width, height: image.height }
  return getCroppedImg(image, crop)
}

export const getCroppedImg = (image, crop) => {
  const canvas = document.createElement('canvas');
  const pixelCrop = getPixelCrop(image, crop)
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );
  
  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(file => {
      file.name = "image.jpg";
      resolve(file);
    }, 'image/jpeg');
  });
}


export const resize = (image, maxSize) => {
  const canvas = document.createElement('canvas');
  let newImg = { width: 0, height: 0 }

  if (image.width > image.height) {
    newImg = { width: maxSize, height: maxSize * image.height / image.width }
  } else {
    newImg = { width: maxSize * image.width / image.height, height: maxSize }
  }

  canvas.width = newImg.width;
  canvas.height = newImg.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    image.x,
    image.y,
    image.width,
    image.height,
    0,
    0,
    newImg.width,
    newImg.height
  );

  // As Base64 string
  const resultImg = new Image();
  resultImg.src = canvas.toDataURL('image/jpeg');
  return resultImg;
}

export const loadImage = (src, sendFile, imageCrop) => {
  var image = new Image();
  image.src = src;
  image.onload = (event) => {
    const img = event.target

    if (img.width < 512 || img.height < 512) {
      alert("Wrong image dimensions (min 512px)")
      return
    }

    if (img.width > 2000 && img.height > 2000) {
      const newImg = resize(img, 2000)
      imageCrop(newImg)
      return
    }

    if (Math.abs(img.width - img.height) < 10) {
      getImage(img)
        .then((img) => {
          sendFile(img)
        })
      return;
    }

    imageCrop(img)
  }
}