import React, { Component } from 'react';
import AddDataForm from '../components/Form/AddDataForm';
import Header from '../components/Header';
import DataList from '../components/DataList';
import CropAlert from '../components/alerts/CropAlert';

class Home extends Component {
  render() {
    let query = new URLSearchParams(this.props.location.search)
    let page = query.get('page')
    let type = query.get('type')
    return (
      <div>
        <Header />
        <AddDataForm />
        <DataList currentPage={page == null ? 1 : page} currentType={type == null ? 0 : type} history={this.props.history} />
        <CropAlert />
      </div>
    );
  }
}

export default Home;