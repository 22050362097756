import React from 'react';
import { connect } from 'react-redux';
import { sendFile, imageCrop } from '../actions/DataActions';
import { loadImage } from '../helpers';
import { startLoading, stopLoading } from '../actions/API';

class UploadImage extends React.Component {
  handleselectedFile = event => {
    let reader = new FileReader();
    let file = event.target.files[0];

    this.props.startLoading()

    if (file === undefined)
      return
    event.target.value = null

    reader.onloadend = () => {
      loadImage(reader.result, this.props.sendFile, this.props.imageCrop)
      this.props.stopLoading()
    }

    reader.readAsDataURL(file)
  }

  renderImage = () => {
    if (this.props.file)
      return <img src={this.props.file} alt='preview' />
  }

  render() {
    return (
      <div className="UploadImage">
        <i className="fas fa-upload"></i>
        {this.renderImage()}
        <input type="file" name="" id="" onChange={this.handleselectedFile} accept='.jpg' />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, { sendFile, imageCrop, startLoading, stopLoading })(UploadImage);