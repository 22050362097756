import React from 'react';

class FormSelect extends React.Component {
  render() {
    var disabledItem = this.props.disabledItem

    const selectProps = {
      value: this.props.value,
      onChange: this.props.onChange
    }

    return (
      <select {...selectProps} className="FormSelect" multiple={this.props.multiple}>
        {this.props.data.map(function (object, i) {
          let disabled = disabledItem === undefined ? false : disabledItem === object.id.toString()
          return <option key={i} disabled={disabled} value={object.id}>{object.name}</option>;
        })}
      </select>
    );
  }
}

export default FormSelect;