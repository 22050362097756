import React from 'react';
import { connect } from 'react-redux';
import { fetchData } from '../actions/DataActions';
import DataItem from './DataItem';
import FilterItem from './items/FilterItem';
import PagerNavigation from './PagerNavigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class DataList extends React.Component {
  constructor(props) {
    super(props);
    this.props.fetchData(this.props.currentPage, this.props.currentType)
  }

  onPageChanged = (page) => {
    this.props.history.push({
        pathname: '/',
        search: '?page=' + page + '&type=' + this.props.currentType
    });

    this.props.fetchData(page, this.props.filter)
  }

  onFilterChanged = (filter) => {
    this.props.history.push({
        pathname: '/',
        search: '?page=' + 1 + '&type=' + filter
    });
    this.props.fetchData(1, filter)
  }

  onTrashTap = () => {
    this.props.fetchData(this.props.page, "trash")
  }

  render() {
    const modulo = this.props.data.length % 3
    const needToAdd = modulo == 0 ? 0 : 3 - this.props.data.length % 3
    return (
      <div>
        <div className="data-list-options">
          <div className="pagination data-list-counts filter-buttons">
              {this.props.counts.map((item, index) => <FilterItem item={item} key={index} filter={this.props.filter} onFilterChanged={this.onFilterChanged} />)}
              <button className={"trash" == this.props.filter ? "active" : ""} onClick={this.onTrashTap}><FontAwesomeIcon className="trash" icon="trash-alt" onClick={this.delete} /></button>
          </div>
          <PagerNavigation page={this.props.page} pages={this.props.pages} onPageChanged={this.onPageChanged} />
        </div>
        <div className="DataList">
          {this.props.data.map((item, index) => <DataItem item={item} key={index} />)}
          {[...Array(needToAdd)].map((v, index) => <DataItem key={(index + this.props.data.length)} />)}
        </div>
        <div className="data-list-options bottom">
          <div></div>
          <PagerNavigation page={this.props.page} pages={this.props.pages} onPageChanged={this.onPageChanged} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { data, user, page, pages, counts, filter } = state.main
  return { data, user, page, pages, counts, filter }
}

export default connect(mapStateToProps, { fetchData })(DataList);