import React, { Component } from 'react';
import FormInput from '../components/Form/FormInput';
import FormButton from '../components/Form/FormButton';
import Loading from '../components/Loading';
import { login } from '../actions/AuthActions';
import { connect } from 'react-redux';

class Login extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLoginChange = this.handleLoginChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);

    this.state = {
      loading: false,
      login: '',
      password: '',
    }
  }

  handleLoginChange(event) {
    this.setState(Object.assign({}, this.state, { login: event.target.value }));
  }

  handlePasswordChange(event) {
    this.setState(Object.assign({}, this.state, { password: event.target.value }));
  }

  handleSubmit(event) {
    this.setState({ loading: true })
    event.preventDefault();
    this.props.login(this.state.login, this.state.password)
  }

  render() {
    if (this.props.loggedIn) {
      window.location = '/'
      return (<div className="App"><Loading /></div>)
    }
    return (
      <form className="Login" onSubmit={this.handleSubmit}>
        <div className="Login-Box">
          <img src="/img/logo.png" alt="Medic Scanner" className="Login-Logo" />
          {this.props.error ? <div className="LoginError">{this.props.error}</div> : null}
          <FormInput hint="Login" name="login" onChange={this.handleLoginChange} />
          <FormInput hint="Password" type="password" name="password" onChange={this.handlePasswordChange} />
          <FormButton type="submit" title="Log in" />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  const { error, loggedIn } = state.auth
  return { error, loggedIn }
}

export default connect(mapStateToProps, { login })(Login);