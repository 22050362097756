import React from 'react';
import { saveDict } from '../../actions/DictActions';
import { connect } from 'react-redux'
import Loading from '../Loading';
import { URL } from '../../actions/API';
import Zoom from 'react-medium-image-zoom'

class DataResultItem extends React.Component {
  messagesEndRef = React.createRef()
  state = {
    orginal: 0,
    segmentation: 0,
    angles: 0,
    color_variety: 0,
  }

  scrollToBottom = () => {
    if (this.messagesEndRef.current !== null)
    this.messagesEndRef.current.scrollTop = 9999
  }
  
  componentDidMount() {
    this.scrollToBottom();
  }
  
  componentDidUpdate() {
    this.scrollToBottom();
  }

  imageError = (e) => {
    this.setState({[e.target.id]: -1})
    e.target.src = ""
  }

  loader = () => {
    if (!this.props.result.processed)
      return <img className="loader" src="/img/91.gif" alt="loading" />
  }

  renderImage = (name, key) => {
    let file = key === "orginal" ? "orginal.jpg" : `orginal/${key}.jpg`
    return (<div>
      <h3>{name}</h3>
      <Zoom zoomMargin={40} overlayBgColorStart='rgba(0, 0, 0, 0.0)' overlayBgColorEnd='rgba(0, 0, 0, 0.5)'>
        <img src={`${URL}logs/correct_check/${this.props.result.uuid}/${file}`} className={this.state[key] === -1 ? "error" : ""} id={key} onError={this.imageError} />
        </Zoom>
    </div>)
  }

  render() {
    if (this.props.result.log === undefined || this.props.result.uuid === null)
      return <h3 className="DataInfoNoLog">No process information</h3>
    
      if (this.props.log === null)
        return <Loading />

    return (
      <div className="DataInfoProcess">
        <div className="DataInfoLog">
          <h3>Process log - {this.props.result.title} {this.loader()}</h3>
          <div className="log" ref={this.messagesEndRef}>{this.props.result.log}{this.props.result.result_message}</div>
        </div>
        <div className="DataInfoGrid">
          {this.renderImage("Orginal", "orginal")}
          {this.renderImage("Segmentation", "segmentation")}
          {this.renderImage("Angles", "angles")}
          {this.renderImage("Color variety", "color_variety")}
        </div>
      </div>)
  }
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, {  })(DataResultItem)
