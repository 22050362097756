import { API_GET_REPORTS_SUCCESS, API_GET_REPORT_SUCCESS, API_DATA_DELETE_SUCCESS } from "../actions/types";

const INITIAL_STATE = {
  data: [],
  report: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case API_GET_REPORTS_SUCCESS:
      return Object.assign({}, state, { data: action.data })
    case API_GET_REPORT_SUCCESS:
        return Object.assign({}, state, { report: action.data })
    case API_DATA_DELETE_SUCCESS:   
        return Object.assign({}, state, { data: state.data.map(item => {
          if (item.data_id === action.id) { item.is_deleted = true} 
          return item
        }) })
    default:
      return state
  }
}