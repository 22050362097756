import React from 'react';

class FormInput extends React.Component {
  render() {
    return (
      <input {...this.props} placeholder={this.props.hint} className="FormInput" />
    );
  }
}

export default FormInput;