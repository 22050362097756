import React, { Component } from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom';
import Login from './sites/Login';
import Home from './sites/Home';
import { connect } from 'react-redux';
import Loading from './components/Loading';
import { logout, checkAuth } from './actions/AuthActions';
import './media/App.scss';
import 'react-medium-image-zoom/dist/styles.css'
import Error from './components/Error';
import Admin from './sites/Admin';
import RecognitionTypes from './sites/admin/RecognitionTypes';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPencilAlt, faSave, faTimes, faSync, faTrashAlt, faCheck, faMinus, faUpload, faClipboardCheck, faSearch, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faClipboard } from '@fortawesome/free-regular-svg-icons'
import Reports from './sites/admin/Reports';
import ReportGenerate from './sites/admin/ReportGenerate';
import Report from './sites/admin/Report';
import DataInfo from './sites/DataInfo';

library.add(faPencilAlt, faSave, faTimes, faSync, faTrashAlt, faCheck, faMinus, faUpload, faClipboardCheck, faClipboard, faSearch, faChevronRight, faChevronLeft)

class App extends Component {
  constructor(props) {
    super(props)
    this.props.checkAuth()
  }

  renderAdminRoutes = () => {
    if (this.props.user === null || !this.props.user.is_admin)
      return
    return [
        <Route key={0} exact path='/admin' component={Admin} />,
        <Route key={1} exact path='/admin/recognition_types' component={RecognitionTypes} />,
        <Route key={2} exact path='/report/generate' component={ReportGenerate} />,
    ]
  }

  render() {
    if (this.props.loggedin === null) {
      return (<div className="App"><Loading /></div>)
    }
    if (this.props.apiError) {
      return (<div className="App"><Error text="Problem with connection to the server." /></div>)
    }

    if (this.props.loggedin === false && window.location.pathname !== '/login') {
      window.location = '/login'
      return (<div className="App"><Loading /></div>)
    }

    return (
      <div className="App">
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/data/:id' component={DataInfo} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/reports' component={Reports} />
          {this.renderAdminRoutes()}
          <Route exact path='/report/:id' component={Report} />
          <Route exact path='/logout' render={() => {
            this.props.logout()
            window.location = '/login'
            return <div className="App"><Loading /></div>
          }} />
          <Route render={() => <Error text="Page not found." />} />
        </Switch>
        {this.props.loading ? <Loading /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, loggedin, apiError, user } = state.main
  return { loading, loggedin, apiError, user }
}

export default connect(mapStateToProps, { logout, checkAuth })(App);
