import React from 'react';
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import FormButton from '../Form/FormButton';
import { connect } from 'react-redux';
import { imageCropped, sendFile } from '../../actions/DataActions';
import { getCroppedImg } from '../../helpers';
import { stopLoading } from '../../actions/API'

class CropAlert extends React.Component {
  state = {
    crop: {
      x: 0,
      y: 0,
      width: 500,
      height: 500,
    }
  }

  onBgClick = () => {
  }

  onImageLoaded = (image, crop) => {
    if (image.naturalWidth > image.naturalHeight) {
      const x = Math.round((image.naturalWidth - image.naturalHeight) / 2)
      const xProcentage = 100 * x / image.naturalWidth
      const newCrop = makeAspectCrop({ aspect: 1, height: 100, x: xProcentage, y: 0 }, image.naturalWidth / image.naturalHeight)
      this.setState(Object.assign({}, { crop: this.state }, { crop: newCrop }))

    } else {
      const y = Math.round((image.naturalHeight - image.naturalWidth) / 2)
      const yProcentage = 100 * y / image.naturalHeight
      const newCrop = makeAspectCrop({ aspect: 1, width: 100, x: 0, y: yProcentage }, image.naturalWidth / image.naturalHeight)
      this.setState(Object.assign({}, { crop: this.state }, { crop: newCrop }))
    }
    this.props.stopLoading()

  }

  onCropChanged = (crop, pixelCrop) => {
    if (pixelCrop.width < 512 || pixelCrop.height < 512)
      return
    this.setState({ crop });

  }

  render() {
    console.log(this.props.imageCrop ? this.props.imageCrop.src : "none")
    if (this.props.imageCrop === null)
      return <div />;
    return (
      <div className="alert-bg" onClick={this.onBgClick}>
        <div className="alert">
          <h2>How to crop</h2>
          <p>
            <small>Skin change has to be inside a circle. Center point of circle has to be over skin change.</small>
          </p>
          <ReactCrop
            className="crop"
            src={this.props.imageCrop.src}
            crop={this.state.crop}
            onChange={this.onCropChanged}
            onImageLoaded={this.onImageLoaded} />
          <FormButton title="Crop image to selected area" onClick={() => {
            getCroppedImg(this.props.imageCrop, this.state.crop, "aa")
              .then((img) => {
                this.props.sendFile(img)
              })
          }} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { imageCrop, pixelCrop } = state.data
  return { imageCrop, pixelCrop }
}

export default connect(mapStateToProps, { sendFile, imageCropped, stopLoading })(CropAlert)
