import { REPORT_INFO_SUCCESS } from "../actions/types";

const INITIAL_STATE = {
  types: [],
  set1: [],
  set2: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REPORT_INFO_SUCCESS:
      return Object.assign({}, state, action.data)
    default:
      return state
  }
}