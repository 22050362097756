import React, { Component } from 'react';
import Header from '../../components/Header';
import { connect } from 'react-redux';
import RecognitionTypeItem from '../../components/items/RecognitionTypeItem';
import FormInput from '../../components/Form/FormInput';
import FormButton from '../../components/Form/FormButton';
import { addDict } from '../../actions/DictActions';

class RecognitionTypes extends Component {
  state = {
    value: ""
  }
  render() {
    return (
      <div>
        <Header />
        <div class="TopBar">
          <h1>Recognition types</h1>
        </div>
        <div className="DataList">
          {this.props.recognition_types.map((item, index) => <RecognitionTypeItem item={item} key={index} />)}
        </div>
        <div className="ListItem FullForm">
          <FormInput value={this.state.value} hint="Name" onChange={(event) => {
            this.setState(Object.assign({}, this.state, { value: event.target.value }))
          }} />
          <FormButton title="Add" disabled={this.state.value.length === 0} onClick={() => {
            this.props.addDict("recognition-types", { name: this.state.value })
            this.setState(Object.assign({}, this.state, { value: "" }))
          }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { recognition_types } = state.main
  return { recognition_types }
}

export default connect(mapStateToProps, { addDict })(RecognitionTypes);