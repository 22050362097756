import { URL, headers } from "./API";
import { API_START, API_END, API_ERROR, API_DICT_UPDATE, API_DICT_ADD } from "./types";

export const saveDict = (dict, json) => (dispatch) => {
  const request = new Request(URL + 'admin/' + dict + "/save", {
    headers: headers(dispatch),
    method: 'POST',
    body: JSON.stringify(json)
  })

  dispatch({ type: API_START })
  fetch(request)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 'ok') {
        dispatch({ type: API_END })
        dispatch({ type: API_DICT_UPDATE, data: json.data })
      }
    })
    .catch(error => {
      dispatch({ type: API_ERROR })
    });
} 

export const addDict = (dict, json) => (dispatch) => {
  const request = new Request(URL + 'admin/' + dict + "/add", {
    headers: headers(dispatch),
    method: 'POST',
    body: JSON.stringify(json)
  })
  dispatch({ type: API_START })
  fetch(request)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 'ok') {
        dispatch({ type: API_END })
        dispatch({ type: API_DICT_ADD, data: json.data })
      }
    })
    .catch(error => {
      dispatch({ type: API_ERROR })
    });
} 