import React, { Component } from 'react';
import Header from '../../components/Header';
import { connect } from 'react-redux';
import { fetchReports, fetchReportsSilently } from '../../actions/ReportActions';
import ReportItem from '../../components/items/ReportItem';

class Reports extends Component {
  constructor(props) {
    super(props);
    this.props.fetchReports()
    setInterval(() => {
      this.props.fetchReportsSilently()
    }, 5000);
  }

  renderGenerateButton = () => {
      if (this.props.user.is_admin)
        return <a className="blue" href="/report/generate">Generate report</a>
  }

  render() {
    return (
      <div>
        <Header />
        <div className="TopBar">
          <h1>Reports</h1>
          {this.renderGenerateButton()}
        </div>

        <div className="DataList ReportList">
          <div className="ListHeader">
            <div>Version</div>
            <div>Data payload</div>
            <div>Set 1</div>
            <div>Set 2</div>
            <div></div>
            <div>Generate at</div>
          </div>
          {this.props.data.map((item, index) => <ReportItem item={item} key={index} />)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { data } = state.report
  const { user } = state.main
  return { data, user }
}

export default connect(mapStateToProps, { fetchReports, fetchReportsSilently })(Reports);