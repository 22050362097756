import React from 'react';
import { connect } from 'react-redux'

class PagerNavigation extends React.Component {
  prevPage = (e) => {
    const prev = parseInt(this.props.page) - 1
    if (prev < 1)
      return
    this.props.onPageChanged(prev)
  }

  nextPage = (e) => {
    const next = parseInt(this.props.page) + 1
    
    if (next > parseInt(this.props.pages))
      return
    this.props.onPageChanged(next)
  }
  render() {
    return (
      <div className="pagination">
        <button onClick={this.prevPage}>{"<"}</button>
        <button disabled>{this.props.page} / {this.props.pages}</button>
        <button onClick={this.nextPage}>{">"}</button>
      </div>
    );
  }
}

export default PagerNavigation;