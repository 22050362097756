import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormInput from './Form/FormInput';
import FormSelect from './Form/FormSelect';
import { connect } from 'react-redux';
import { saveData, dataStatus, dataDelete, dataPublish } from '../actions/DataActions';

class DataItem extends React.Component {
  state = {
    id: -1,
    edit: false,
    source: '',
    type: 0,
    for_train: false,
    for_report: false,
  }

  componentDidMount() {
    this.setState(Object.assign({}, this.state, {
      id: this.props.item.id,
      source: this.props.item.source,
      type: this.props.item.type.id,
      for_train: this.props.item.for_train,
      for_report: this.props.item.for_report,
    }))
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.item === undefined)
      return;
    
    if (prevProps.item !== undefined && this.state.id === this.props.item.id)
      return
    
    this.setState(Object.assign({}, this.state, {
      id: this.props.item.id,
      source: this.props.item.source,
      type: this.props.item.type.id,
      for_train: this.props.item.for_train,
      for_report: this.props.item.for_report,
    }))
  }


  onChangeType = (event) => {
    this.setState(Object.assign(this.state, { type: event.target.value }));
  }

  onChangeSource = (event) => {
    this.setState(Object.assign(this.state, { source: event.target.value }));
  }

  renderRecognition() {
    if (this.state.edit) {
      return <FormSelect
        data={this.props.recognition_types}
        onChange={this.onChangeType}
        value={this.state.type} />
    }
    return this.props.item.type.name
  }

  renderSource() {
    if (this.state.edit) {
      return <FormInput value={this.state.source} onChange={this.onChangeSource} />
    }
    if (this.props.item.source.includes('http'))
      return <a href={this.props.item.source} target="_blank" rel="noopener noreferrer">{this.props.item.source}</a>
    return this.props.item.source
  }

  renderCreator() {
    if (this.state.edit) {
      return
    }
    if (this.props.item.user) {
      return (<div className="DataItemProp DataItemCreator">
        <small>CREATOR</small>
        @{this.props.item.user.name}
      </div>)
    }
  }

  edit = () => {
    if (!this.props.user.is_editor) 
        return
    this.setState(Object.assign({}, this.state, { edit: true }))
  }

  delete = () => {
    if (!this.props.user.is_editor) 
        return
    if (window.confirm("Are you sure you want to delete this item?")) {
      this.props.dataDelete(this.props.item.id)
    }
  }

  publish = () => {
    this.props.dataPublish(this.props.item.id)
  }

  save = () => {
    if (!this.props.user.is_editor) 
        return
    this.setState(Object.assign({}, this.state, { edit: false }))
    this.props.saveData({
      id: this.props.item.id,
      source: this.state.source,
      type: this.state.type,
      for_train: this.state.for_train,
      for_report: this.state.for_report
    })
  }

  close = () => {
    this.setState(Object.assign({}, this.state, { edit: false }))
  }

  refresh = () => {
    if (!this.props.user.is_editor) 
        return
    this.props.dataStatus(this.props.item.id, 1)
  }

  renderDelete() {
    if (!this.props.user.is_editor) 
        return

    if (this.state.edit) {
      return
    }

    if (this.props.filter == "trash")
      return <FontAwesomeIcon className="trash" icon="upload" onClick={this.publish} />

    return <FontAwesomeIcon className="trash" icon="trash-alt" onClick={this.delete} />
  }

  renderEdit() {
    if (!this.props.user.is_editor) 
        return
    if (!this.state.edit)
      return <FontAwesomeIcon className="edit" icon="pencil-alt" onClick={this.edit} />
  }

  renderSave() {
    if (!this.props.user.is_editor) 
        return

    if (this.state.edit) {
      return <div className="save"><button className="FormButton" onClick={this.save}>Save</button></div>
    }
    
  }

  renderClose() {
    if (this.state.edit) {
      return <FontAwesomeIcon className="close" icon="times" onClick={this.close} />
    }
  }

  renderResult() {
    if (this.state.edit) {
      return
    }
    const lastResult = this.props.item.last_result

    if (lastResult === false) {
      if (this.props.item.status != 1 && this.props.item.status != 0)
        return (
          <span className="lastResult">
            <FontAwesomeIcon icon="sync" onClick={this.refresh} />
            {lastResult.result_message}
          </span>
        )
      return
    }

    if (lastResult.result <= 0) {
      return (
        <span className="lastResult">
          <FontAwesomeIcon icon="sync" onClick={this.refresh} />
          {lastResult.result_message}
        </span>
      )
    }

    return (
      <span className="lastResult">
        <FontAwesomeIcon icon="sync" onClick={this.refresh} />
        {lastResult.result}%
    </span>
    )
  }

  purposeChanged = (event) => {
    if (event.target.name == "for_train")
      this.setState(Object.assign(this.state, { for_train: !this.state.for_train }));

    if (event.target.name == "for_report")
      this.setState(Object.assign(this.state, { for_report: !this.state.for_report }));
    this.save()
  }

  renderStatus() {
    if (!this.props.user.is_admin)
      return

    if (this.props.item.status === 0)
      return (<div className="StatusButtons">
        <button className="correct" onClick={() => this.props.dataStatus(this.props.item.id, 1)}>Correct</button>
        <button className="wrong" onClick={() => this.props.dataStatus(this.props.item.id, -1)}>Wrong</button>
      </div>)

    if (this.props.item.status === 1)
      return <div className="checking"></div>
    if (this.props.item.status === 2)
      return <div className="correct"></div>
    if (this.props.item.status === -1)
      return <div className="wrong"></div>
    if (this.props.item.status === -2)
      return <div className="wrong"></div>
  }

  openInfo = (e) => {
    window.location.href = "/data/" + this.props.item.id
  }

  renderLink = () => {
    if (this.state.edit)
      return
    return (<div className="DataItemLink" onClick={this.openInfo}></div>)
  }

  renderApproved = () => {
    console.log(this.props.item.approved_by);

    if (this.props.item.approved_by == null)
      return <div className="approved not-approved">
        <FontAwesomeIcon className="approved-icon" icon={["far", "clipboard"]} />
        Not approved
      </div>
    return <div className="approved">
      <FontAwesomeIcon className="approved-icon" icon={["fas", "clipboard-check"]} />
      Approved by {this.props.item.approved_by.name}
    </div>
  }

  render() {
    if (!this.props.item) {
      return (<div className="DataItem DataItemEmpty"></div>)
    }

    return (<div className="DataItem" key={this.props.key}>
      <div className="DataItemRow">
        {this.renderLink()}
        <img src={this.props.item.image} alt="preview" />
        <div className="DataItemContent">
            <div className="DataItemProp">
            <small>RECOGNITION</small>
            {this.renderRecognition()}
            </div>
            <div className="DataItemProp DataItemSource">
            <small>SOURCE</small>
            {this.renderSource()}
            </div>
            {this.renderCreator()}
            {this.renderApproved()}
        </div>
        {this.renderDelete()}
        {this.renderEdit()}
        {this.renderClose()}
        {this.renderStatus()}
      </div>
      <div className="DataPurpose">
        <label><input type="checkbox" name="for_train" checked={this.state.for_train} onChange={this.purposeChanged} /> Training set</label>
        <label><input type="checkbox" name="for_report" checked={this.state.for_report} onChange={this.purposeChanged} /> Report set</label>
        {this.renderResult()}
        {this.renderSave()}
      </div>
    </div>)
  }
}

const mapStateToProps = (state) => {
  const { user, recognition_types, filter } = state.main
  return { user, recognition_types, filter }
}

export default connect(mapStateToProps, { saveData, dataStatus, dataDelete, dataPublish })(DataItem)