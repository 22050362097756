import { API_START, API_GET_REPORTS_SUCCESS, API_END, API_GET_REPORT_SUCCESS, REPORT_INFO_SUCCESS } from "./types";
import { URL, headers } from "./API";

export const fetchReports = () => (dispatch) => {
  dispatch({ type: API_START })
  const request = new Request(URL + 'report/list', {
    method: 'GET',
    headers: headers(dispatch)
  })
  fetch(request)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 'ok') {
        dispatch({ type: API_GET_REPORTS_SUCCESS, data: json.data })
      }
      dispatch({ type: API_END })
    })
    .catch(error => {
      dispatch({ type: API_END })
    });
}

export const generateReport = (params) => (dispatch) => {
  dispatch({ type: API_START })
  const request = new Request(URL + 'report/generate', {
    method: 'POST',
    headers: headers(dispatch),
    body: JSON.stringify(params)
  })
  fetch(request)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 'ok') {
        window.location = '/reports'
      }
      dispatch({ type: API_END })
    })
    .catch(error => {
      dispatch({ type: API_END })
    });
}

export const fetchReportsSilently = () => (dispatch) => {
  const request = new Request(URL + 'report/list', {
    method: 'GET',
    headers: headers(dispatch)
  })
  fetch(request)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 'ok') {
        dispatch({ type: API_GET_REPORTS_SUCCESS, data: json.data })
      }
    })
    .catch(error => {
    });
}

export const fetchReport = (id) => (dispatch) => {
  dispatch({ type: API_START })
  const request = new Request(URL + 'report/' + id, {
    method: 'GET',
    headers: headers(dispatch)
  })
  fetch(request)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 'ok') {
        dispatch({ type: API_GET_REPORT_SUCCESS, data: json.data })
      }
      dispatch({ type: API_END })
    })
    .catch(error => {
      dispatch({ type: API_END })
    });
}

export const fetchInfoReport = (params) => (dispatch) => {
  dispatch({ type: API_START })
  const request = new Request(URL + 'report/info', {
    method: 'POST',
    headers: headers(dispatch),
    body: JSON.stringify(params)
  })
  fetch(request)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 'ok') {
        dispatch({ type: REPORT_INFO_SUCCESS, data: json.data })
      }
      dispatch({ type: API_END })
    })
    .catch(error => {
      dispatch({ type: API_END })
    });
}

export const fetchReportSilently = (id) => (dispatch) => {
  const request = new Request(URL + 'report/' + id, {
    method: 'GET',
    headers: headers(dispatch)
  })
  fetch(request)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 'ok') {
        dispatch({ type: API_GET_REPORT_SUCCESS, data: json.data })
      }
    })
    .catch(error => {
    });
}