import React from 'react';

class LinkBox extends React.Component {
  render() {
    return (
      <a className="LinkBox" href={this.props.path}>
        {this.props.title}
      </a>
    );
  }
}

export default LinkBox;