import { URL, headers } from "./API";
import { API_START, API_LOGIN_ERROR, API_LOGIN_SUCCESS, API_END, API_AUTCH_CHECK_OK, API_AUTCH_CHECK_ERROR, API_ERROR } from "./types";

export const login = (login, password) => (dispatch) => {
  const params = {
    method: 'POST',
    body: JSON.stringify({
      login: login,
      password: password
    })
  }

  dispatch({ type: API_START })
  fetch(URL + 'login', params)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 'ok') {
        localStorage.setItem('token', json.data.token)
        dispatch({ type: API_END })
        dispatch({ type: API_LOGIN_SUCCESS })
      } else {
        fail(dispatch, 'Wrong credentials')
      }
    })
    .catch(error => {
      fail(dispatch, 'Wrong credentials')
      console.error('Error:', error)
    });
}

export const checkAuth = () => (dispatch) => {
  const request = new Request(URL + 'start', {
    headers: headers(dispatch),
  })
  dispatch({ type: API_START })
  fetch(request)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 'ok') {
        dispatch({ type: API_END })
        dispatch({ type: API_AUTCH_CHECK_OK, data: json.data })
      } else {
        checkFail(dispatch)
      }
    })
    .catch(error => {
      console.log(error);

      checkFail(dispatch)
      dispatch({ type: API_ERROR })
    });
}

export const logout = () => (dispatch) => {
  localStorage.clear()
}

const fail = (dispatch, message = '') => {
  dispatch({ type: API_END })
  dispatch({ type: API_LOGIN_ERROR, message: message })
}

const checkFail = (dispatch) => {
  dispatch({ type: API_END })
  dispatch({ type: API_AUTCH_CHECK_ERROR })
}