import React from 'react';
import { connect } from 'react-redux'

class FilterItem extends React.Component {

  onFilterChanged = (e) => {
    this.props.onFilterChanged(this.props.item.id)
  }

  renderCounts = () => {
    if (this.props.item.id == 0)
      return this.props.item.count
    return <div>{this.props.item.count} / <span>{this.props.item.ok_count}</span></div>
  }

  render() {
    return <button className={this.props.item.id == this.props.filter ? "active" : ""} onClick={this.onFilterChanged}>{this.props.item.name} {this.renderCounts()}</button>
  }
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, {  })(FilterItem)
