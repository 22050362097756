import React from 'react';

class ReportResultItem extends React.Component {
  statusBorder() {
    if (this.props.item.processed && this.props.item.result > 0) {
      return <div className="correct"></div>
    }
    if (this.props.item.processed && this.props.item.result === 0) {
      return <div className="wrong"></div>
    }
  }

  status() {
    if (this.props.item.processed && this.props.item.result > 0) {
      return <div className="status success">Processed</div>
    }
    if (this.props.item.processed && this.props.item.result === 0) {
      return <div className="status error">Error</div>
    }
    return (<div className="status">Waiting <img src="/img/91.gif" alt="loading" /></div>)
  }

  renderDeleteAction() {
    if (this.props.onDeleteItem === null)
        return

    return !this.props.item.is_deleted
        ? <button className="destructive" onClick={() => this.props.onDeleteItem(this.props.item.data_id)}>Delete</button> 
        : <button className="destructive" disabled>Deleted</button>
  }

  renderActions() {
    return (
      <div className="ReportResultItemActions">
        <div className="groups">
        <button>{this.props.item.type.name}</button>
        </div>
        {this.renderDeleteAction()}
      </div>
    )
  }

  render() {
    const deletedClass = this.props.item.is_deleted ? 'deleted' : '';
    return (
      <div className={`ReportResultItem ${deletedClass}`}>
        {this.statusBorder()}
        <div className="ReportResultItemInfo">
          <img src={this.props.item.image} />
          {this.status()}
          <div className="result">{Math.round(this.props.item.result)}%</div>
        </div>
        {/* {this.props.item.is_deleted || !this.props.item.processed ? null : this.renderActions()} */}
        {this.renderActions()}
      </div>
    );
  }
}

export default ReportResultItem;