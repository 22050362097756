export const API_START = 'api_start'
export const API_END = 'api_end'
export const API_ERROR = 'api_error'

export const API_LOGIN_SUCCESS = 'api_login_success'
export const API_LOGIN_ERROR = 'api_login_error'
export const API_AUTCH_CHECK_OK = 'api_auth_check_ok'
export const API_AUTCH_CHECK_ERROR = 'api_auth_check_error'

export const API_DATA_FILE_UPLOADED = 'api_data_file_upladed'
export const API_DATA_FILE_ERROR = 'api_data_file_error'

export const API_GET_DATA_SUCCESS = 'api_get_data_success'
export const API_GET_DATA_ITEM_SUCCESS = 'api_get_data_item_success'
export const API_DATA_SUCCESS = 'api_data_success'
export const API_DATA_UPDATE_SUCCESS = 'api_data_update_success'
export const API_DATA_ERROR = 'api_data_error'
export const API_DATA_DELETE_SUCCESS = 'api_data_delete'
export const API_DATA_ITEM_RESULTS_SUCCESS = 'api_data_item_results_success'

export const IMAGE_CROP = 'image_crop'
export const IMAGE_CROPPED = 'image_cropped'
export const IMAGE_FROM_URL = 'image_from_url'

export const API_DICT_UPDATE = 'dict_update'
export const API_DICT_ADD = 'dict_add'

export const API_GET_REPORTS_SUCCESS = 'api_get_reports_success'
export const API_GET_REPORT_SUCCESS = 'api_get_report_success'

export const FILTER_SELECTED = 'flter_selected'

export const REPORT_INFO_SUCCESS = 'report_info_success'