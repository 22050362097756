import React from 'react';

class Loading extends React.Component {
  render() {
    return (
      <div className="Loading"><img src="/img/91.gif" alt="loading" /></div>
    );
  }
}

export default Loading;