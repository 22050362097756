import React, { Component } from 'react';
import Header from '../components/Header';
import { connect } from 'react-redux';
import { fetchDataInfo, dataStatus, approve, saveData } from '../actions/DataActions';
import Loading from '../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { URL } from '../actions/API';
import DataResultItem from '../components/items/DataResultItem';
import FormSelect from '../components/Form/FormSelect';

class DataInfo extends Component {
  state = {
    id: -1,
    type: 0,
    for_train: false,
    for_report: false
  }
  constructor(props) {
    super(props);
    this.props.fetchDataInfo(props.match.params.id)
  }

  onChangeType = (e) => {
    this.props.saveData({
      id: this.props.item.id,
      type: e.target.value,
      for_train: false
    })
  }

  onChangePurpose = (type, value) => {
    this.props.saveData({
      id: this.props.item.id,
      type: this.props.item.type.id,
      for_train: type == "for_train" ? value : this.props.item.for_train,
      for_report: type == "for_report" ? value : this.props.item.for_report
    })
  }


  renderStatus() {
    if (this.props.item.status === 1)
      return <div className="checking"></div>
    if (this.props.item.status === 2)
      return <div className="correct"></div>
    if (this.props.item.status === -1)
      return <div className="wrong"></div>
    if (this.props.item.status === -2)
      return <div className="wrong"></div>
  }

  refresh = () => {
    if (!this.props.user.is_editor)
      return
    this.props.dataStatus(this.props.item.id, 1)
    this.props.fetchDataInfo(this.props.item.id)
  }

  toggleApprove = () => {
    if (!this.props.user.is_admin)
      return
    this.props.approve(this.props.item.id)
    // this.props.fetchDataInfo(this.props.item.id)
  }

  renderResult() {
    const lastResult = this.props.item.last_result

    if (lastResult === false) {
      if (this.props.item.status != 1 && this.props.item.status != 0)
        return (
          <span className="lastResult">
            {/* <FontAwesomeIcon icon="sync" onClick={this.refresh} /> */}
            {lastResult.result_message}
          </span>
        )
      return
    }

    if (lastResult.result <= 0) {
      return (
        <span className="lastResult">
          {/* <FontAwesomeIcon icon="sync" onClick={this.refresh} /> */}
          {lastResult.result_message}
        </span>
      )
    }

    return (
      <span className="lastResult">
        {/* <FontAwesomeIcon icon="sync" onClick={this.refresh} /> */}
        {lastResult.result}%
      </span>
    )
  }

  renderResultInfo = () => {
    if (this.props.results === undefined)
      return <h3 className="DataInfoNoLog">No process information</h3>

    if (this.props.results === null)
      return <Loading />

    return this.props.results.map((item, index) => <DataResultItem key={index} result={item} />)

  }

  renderApproveButton = () => {
    console.log(this.props);
    if (this.props.item.approved_by == null)
      return <a href="#" className={"DataInfoApprove" + (this.props.user.is_admin ? "" : " disabled")} onClick={this.toggleApprove}><FontAwesomeIcon icon="check" /> Approve</a>

    if (this.props.item.approved_by.id == this.props.user.id)
      return <a href="" className={"DataInfoApproved WithdrawApproval"} title="Withdraw approval" onClick={this.toggleApprove}><FontAwesomeIcon icon="check" /> Approved by {this.props.item.approved_by.name}</a>

    return <div className={"DataInfoApproved"}><FontAwesomeIcon icon="check" /> Approved by {this.props.item.approved_by.name}</div>
  }

  renderPreviusLink = () => {
    if (this.props.item.prev_id != null)
      return <a href={"/data/" + this.props.item.prev_id}><FontAwesomeIcon icon="chevron-left" /> Previous</a>
    return <span class="disabled"><FontAwesomeIcon icon="chevron-left" /> Previous</span>
  }

  renderNextLink = () => {
    if (this.props.item.next_id != null)
      return <a href={"/data/" + this.props.item.next_id}>Next <FontAwesomeIcon icon="chevron-right" /></a>
    return <span className="disabled">Next <FontAwesomeIcon icon="chevron-right" /></span>
  }

  render() {
    if (!this.props.item)
      return <Loading />

    return (
      <div>
        <Header />
        <div className="TopBar">
          <h1>Data item</h1>
          <div className="DataInfoButtons">
            {this.renderPreviusLink()}
            {this.renderNextLink()}
            {this.renderApproveButton()}
            <a href="#" className={"blue DataInfoRefresh" + (this.props.user.is_editor ? "" : " disabled")} onClick={this.refresh}><FontAwesomeIcon icon="sync" /> Make recognition again</a>
          </div>
        </div>

        <div className="DataInfo">
          {this.renderStatus()}
          <img className="DataInfoMainImage" src={this.props.item.image} />
          <div>
            <h3>Source</h3>
            {this.props.item.source}
          </div>

          <div>
            <h3>Author</h3>
            @{this.props.item.user.name}
          </div>

          <div>
            <h3>Type</h3>
            <FormSelect
              data={this.props.recognition_types}
              onChange={this.onChangeType}
              value={this.props.item.type.id} />
          </div>
          <div>
            <h3>Train set</h3>
            {
              this.props.item.for_train
                ? <FontAwesomeIcon icon="check" onClick={() => this.onChangePurpose("for_train", false)} />
                : <FontAwesomeIcon icon="minus" onClick={() => this.onChangePurpose("for_train", true)} />
            }

          </div>

          <div>
            <h3>Report set</h3>
            {
            this.props.item.for_report 
              ? <FontAwesomeIcon icon="check" onClick={() => this.onChangePurpose("for_report", false)} />
              : <FontAwesomeIcon icon="minus" onClick={() => this.onChangePurpose("for_report", true)} />
            }
          </div>
          <div>
            <h3>Last result</h3>
            {this.renderResult()}
          </div>
        </div>
        {this.renderResultInfo()}
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { currentItem, currentItemResults } = state.data
  const { user, recognition_types } = state.main
  const item = currentItem
  const results = currentItemResults.filter((result) => result.uuid && (result.log || result.result_message))

  return { item, results, user, recognition_types }
}

export default connect(mapStateToProps, { fetchDataInfo, dataStatus, approve, saveData })(DataInfo);