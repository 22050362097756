import React from 'react';
import PropTypes from 'prop-types';

class FormButton extends React.Component {
  render() {
    return (
      <button {...this.props} className="FormButton">{this.props.title}</button>
    );
  }
  static propTypes = {
    title: PropTypes.string.isRequired,
    onPress: PropTypes.func
  }
}

export default FormButton;