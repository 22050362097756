
import React, { Component } from 'react';
import Header from '../../components/Header';
import { connect } from 'react-redux';
import { generateReport, fetchInfoReport } from '../../actions/ReportActions';
import FormInput from '../../components/Form/FormInput';
import FormSelect from '../../components/Form/FormSelect';
import FormLabel from '../../components/Form/FormLabel';
import FormButton from '../../components/Form/FormButton';
import FormGeneratorSets from '../../components/Form/FormGeneratorSets';
import { PieChart } from 'react-minimal-pie-chart';

class ReportGenerate extends Component {
  state = {
    count: 10,
    set1: [this.props.recognition_types[0].id],
    set2: [this.props.recognition_types[1].id],
    angleGuard: 0,
    brightnessGuard: 0,
    colorGuard: 0,
    surfaceMaskGuard: 0
  }

  componentDidMount() {
    this.props.fetchInfoReport({ count: this.state.count, set1: this.state.set1, set2: this.state.set2 })
  }

  canGenerate = () => {
    return this.props.set1.length > 0 && this.props.set2.length > 0
  }

  set1Changed = (event) => {
    var newSet = this.state.set1
    let value = parseInt(event.target.value)
    if (this.state.set1.indexOf(value) >= 0) {
      newSet = this.state.set1.filter((v) => { return v !== value })
    } else {
      newSet.push(value)
    }
    this.setState(Object.assign({}, this.state, { set1: newSet }))
    this.props.fetchInfoReport({ count: this.state.count, set1: newSet, set2: this.state.set2 })
  }

  set2Changed = (event) => {
    var newSet = this.state.set2
    let value = parseInt(event.target.value)
    if (this.state.set2.indexOf(value) >= 0) {
      newSet = this.state.set2.filter((v) => { return v !== value })
    } else {
      newSet.push(value)
    }
    this.setState(Object.assign({}, this.state, { set2: newSet }))
    this.props.fetchInfoReport({ count: this.state.count, set1: this.state.set1, set2: newSet })
  }

  countChange = (event) => {
    this.setState(Object.assign({}, this.state, { count: event.target.value }))
    this.props.fetchInfoReport({ count: event.target.value, set1: this.state.set1, set2: this.state.set2 })
  }

  versionChange = (event) => {
    this.setState(Object.assign({}, this.state, { version: event.target.value }))
  }

  angleGuardChanged = (event) => {
    this.setState(Object.assign({}, this.state, { angleGuard: event.target.value }))
  }

  brightnessGuardChanged = (event) => {
    this.setState(Object.assign({}, this.state, { brightnessGuard: event.target.value }))
  }

  colorGuardChanged = (event) => {
    this.setState(Object.assign({}, this.state, { colorGuard: event.target.value }))
  }

  surfaceMaskGuardChanged = (event) => {
    this.setState(Object.assign({}, this.state, { surfaceMaskGuard: event.target.value }))
  }

  generateClick = () => {
    if (this.canGenerate())
      this.props.generateReport(Object.assign({}, this.state, { version: this.props.kernel_version }))
  }

  renderCharts = () => {
    if (this.props.set1.length == 0)
    return <div className="ReportNotEnough">Not enough data. Change the data payload or select other sets.</div>
    return (
      <div className="ReportCharts">
        <div className="ReportChart">
          <PieChart
            data={this.props.set1}
            lineWidth={20}
            paddingAngle={15}
            rounded
            label={({ dataEntry }) => Math.round(dataEntry.pr * 100) + "%"}
            labelStyle={(index) => ({
              fill: '#000',
              fontSize: '5px',
              fontFamily: 'sans-serif',
              stroke: '#fff',
              fontWeight: 700,
              strokeWidth: '1',
              paintOrder: 'stroke'
            })}
            labelPosition={60}
          />
        </div>

        <div className="ReportChart">
          <PieChart
            data={this.props.set2}
            lineWidth={20}
            paddingAngle={15}
            rounded
            label={({ dataEntry }) => Math.round(dataEntry.pr * 100) + "%"}
            labelStyle={(index) => ({
              fill: '#000',
              fontSize: '5px',
              fontFamily: 'sans-serif',
              stroke: '#fff',
              fontWeight: 700,
              strokeWidth: '1',
              paintOrder: 'stroke'
            })}
            labelPosition={60}
          />
        </div>
        <div className="ReportLabels">
          <h3>Set 1:</h3>
          <div>
            {this.props.set1.map((item, index) =>
              <div className="ReportLabel" key={index}>
                <span className="LabelColor" style={{ backgroundColor: item.color }}></span>
                <span className="LabelName">{item.title}</span>
                <span className="LabelCount">{item.value}</span>
              </div>
            )}
          </div>
          <br />
          <h3>Set 2:</h3>
          <div>
            {this.props.set2.map((item, index) =>
              <div className="ReportLabel" key={index}>
                <span className="LabelColor" style={{ backgroundColor: item.color }}></span>
                <span className="LabelName">{item.title}</span>
                <span className="LabelCount">{item.value}</span>
              </div>
            )}
          </div>
        </div>
      </div>)
  }

  render() {
    const counts = [
      // { id: 2, name: 2 },
      { id: 10, name: 10 },
      { id: 25, name: 25 },
      { id: 50, name: 50 },
      { id: 100, name: 100 },
      { id: 250, name: 250 },
      { id: 500, name: 500 },
      { id: 1000, name: 1000 }
    ]
    const checkboxOptions = [
      { id: 1, name: "On" },
      { id: 0, name: "Off" }
    ]
    
    return (
      <div>
        <Header />
        <div className="TopBar">
          <h1>Generate report</h1>
        </div>

        <div className="Form ReportGenerateFrom">
          <FormLabel text="Kernel version" />
          <FormInput hint="Kernel version" value={this.props.kernel_version} onChange={this.versionChange} disabled />
          <FormLabel text="Data payload" />
          <FormSelect data={counts} value={this.state.count} onChange={this.countChange} />

          <div className="GenerateRaportSets">
            <div>
              <FormLabel text="Set 1" />
              <FormGeneratorSets data={this.props.types} value={this.state.set1} onChange={this.set1Changed} disabledItems={this.state.set2} set={1} />
            </div>
            <div>
              <FormLabel text="Set 2" />
              <FormGeneratorSets data={this.props.types} value={this.state.set2} onChange={this.set2Changed} disabledItems={this.state.set1} set={2} />
            </div>
            {this.renderCharts()}
          </div>

          <div className="Guards">
            <div>
              <FormLabel text="Angle guard" />
              <FormSelect data={checkboxOptions} value={this.state.angleGuard} onChange={this.angleGuardChanged} />
            </div>
            <div>
              <FormLabel text="Brightness guard" />
              <FormSelect data={checkboxOptions} value={this.state.brightnessGuard} onChange={this.brightnessGuardChanged} />
            </div>
            <div>
              <FormLabel text="Color guard" />
              <FormSelect data={checkboxOptions} value={this.state.colorGuard} onChange={this.colorGuardChanged} />
            </div>
            <div>
              <FormLabel text="Surface mask guard" />
              <FormSelect data={checkboxOptions} value={this.state.surfaceMaskGuard} onChange={this.surfaceMaskGuardChanged} />
            </div>
          </div>
          <FormButton title="Generate" onClick={this.generateClick} disabled={!this.canGenerate()} />
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { data } = state.report
  const { types, set1, set2 } = state.reportGenerator
  const { recognition_types, kernel_version } = state.main
  return { data, types, set1, set2, kernel_version, recognition_types }
}

export default connect(mapStateToProps, { generateReport, fetchInfoReport })(ReportGenerate);