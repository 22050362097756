import React from 'react';
import { connect } from 'react-redux'

class Header extends React.Component {
  isActive = (path) => {
    const locpath = window.location.pathname
    if (path === "/")
      return locpath === path ? "active" : null
    return locpath.startsWith(path) ? "active" : null
  }

  renderAdminMenu() {
    if (!this.props.user.is_admin) {
      return []
    }
    return [
      <a key={0} href="/admin" className={this.isActive("/admin")}>Admin</a>
    ]
  }

  render() {
    return (
      <div className="Header">
        <img src="/img/logo.png" className="Logo" alt="Medic Scanner" onClick={() => window.location = '/'} />

        <span className="MenuUser">Logged in as <strong>{this.props.user.name}</strong></span>
        <div className="MenuRight">
          <a href="/" className={this.isActive("/")}>Home</a>
            <a href="/reports" className={this.isActive("/report")}>Reports</a>
          {this.renderAdminMenu()}
          <a href="/logout">Logout</a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.main

  return { user }
}

export default connect(mapStateToProps, {})(Header);