import { API_AUTCH_CHECK_ERROR, API_END, API_START } from "./types";

export const URL =(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    ? 'https://127.0.0.1:8000/'
    : window.location.origin.replace('//', '//api.') + '/';

export const headers = (dispatch) => {
  const token = localStorage.getItem('token')
  
  if (token === null && dispatch !== null) {
    dispatch({ type: API_END })
    dispatch({ type: API_AUTCH_CHECK_ERROR})
    return
  }
  return new Headers({
    "x-auth-token": token,
    "content-type": 'application/json'
  })
}

export const startLoading = () => (dispatch) => {
  dispatch({ type: API_START })
}

export const stopLoading = () => (dispatch) => {
  dispatch({ type: API_END })
}