import { URL, headers } from "./API";
import { API_START, API_END, API_DATA_FILE_UPLOADED, API_DATA_FILE_ERROR, API_DATA_SUCCESS, API_DATA_ERROR, API_GET_DATA_SUCCESS, IMAGE_CROPPED, IMAGE_CROP, IMAGE_FROM_URL, API_DATA_UPDATE_SUCCESS, API_DATA_DELETE_SUCCESS, FILTER_SELECTED, API_GET_DATA_ITEM_SUCCESS, API_DATA_ITEM_RESULTS_SUCCESS } from "./types";

export const fetchData = (page, filter) => (dispatch) => {
  if (page === undefined) {
    page = 1
  }
  if (filter === undefined) {
    filter = 0
  }
  dispatch({ type: FILTER_SELECTED, filter: filter })
  dispatch({ type: API_START })
  const request = new Request(URL + 'data/list?page=' + page + "&type=" + filter, {
    method: 'GET',
    headers: headers(dispatch)
  })
  fetch(request)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 'ok') {
        dispatch({ type: API_GET_DATA_SUCCESS, data: json.data })
      }
      dispatch({ type: API_END })
    })
    .catch(error => {
      dispatch({ type: API_END })
    });
}

export const fetchDataInfo = (id) => (dispatch) => {
  dispatch({ type: API_START })
  const request = new Request(URL + 'data/item/' + id, {
    method: 'GET',
    headers: headers(dispatch)
  })
  fetch(request)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 'ok') {
        dispatch({ type: API_GET_DATA_ITEM_SUCCESS, data: json.data })
        dispatch(fetchDataInfoResults(id))
      }
      dispatch({ type: API_END })
    })
    .catch(error => {
      dispatch({ type: API_END })
    });
}

export const fetchDataInfoResults = (id) => (dispatch) => {
  dispatch({ type: API_START })
  const request = new Request(URL + 'data/item/' + id + '/results', {
      method: 'GET',
      headers: headers(dispatch)
  })
  fetch(request)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 'ok') {
        dispatch({ type: API_DATA_ITEM_RESULTS_SUCCESS, data: json.data })
      }
      dispatch({ type: API_END })
    })
    .catch(error => {
    dispatch({ type: API_END })
    });
}

export const sendData = (json) => (dispatch) => {
  dispatch({ type: API_START })
  const request = new Request(URL + 'data/create', {
    method: 'POST',
    headers: headers(dispatch),
    body: JSON.stringify(json)
  })
  var promise = new Promise(function (resolve, reject) {
    fetch(request)
      .then((response) => response.json())
      .then((json) => {
        if (json.status === 'ok') {
          dispatch({ type: API_DATA_SUCCESS, data: json.data })
          resolve(true);
        } else {
          dispatch({ type: API_DATA_ERROR, error: json.data })
          resolve(false);
        }
        dispatch({ type: API_END })
      })
      .catch(error => {
        dispatch({ type: API_END })
        resolve(false);
      });
  })
  return promise
}

export const sendFile = (file) => (dispatch) => {
  dispatch({ type: API_START })
  const request = new Request(URL + 'data/uploadPhoto', {
    method: 'POST',
    headers: headers(dispatch),
    body: file
  })
  fetch(request)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 'ok') {
        dispatch({ type: API_DATA_FILE_UPLOADED, file: json.data })
      } else {
        dispatch({ type: API_DATA_FILE_ERROR, error: json.data })
      }

      dispatch({ type: API_END })
    })
    .catch(error => {
      dispatch({ type: API_DATA_FILE_ERROR, error: 'unknown' })
      dispatch({ type: API_END })
    });
}


export const saveData = (json) => (dispatch) => {
  let id = json.id
  dispatch({ type: API_START })
  const request = new Request(URL + 'data/save', {
    method: 'POST',
    headers: headers(dispatch),
    body: JSON.stringify(json)
  })
  fetch(request)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 'ok') {
        console.log(id);
        dispatch(fetchDataInfo(id))
        dispatch({ type: API_DATA_UPDATE_SUCCESS, data: json.data })
      } else {
        dispatch({ type: API_DATA_ERROR, error: json.data })
      }
      dispatch({ type: API_END })
    })
    .catch(error => {
      dispatch({ type: API_END })
    });
}

export const dataStatus = (id, status) => (dispatch) => {
  dispatch({ type: API_START })
  const request = new Request(URL + 'data/status', {
    method: 'POST',
    headers: headers(dispatch),
    body: JSON.stringify({
      id: id,
      status: status
    })
  })
  fetch(request)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 'ok') {
        dispatch({ type: API_DATA_UPDATE_SUCCESS, data: json.data })
      } else {
        dispatch({ type: API_DATA_ERROR, error: json.data })
      }
      dispatch({ type: API_END })
    })
    .catch(error => {
      dispatch({ type: API_END })
    });
}

export const approve = (id) => (dispatch) => {
  dispatch({ type: API_START })
  const request = new Request(URL + 'data/item/' + id + '/approve', {
    headers: headers(dispatch)
  })
  fetch(request)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 'ok') {
        dispatch({ type: API_DATA_UPDATE_SUCCESS, data: json.data })
        dispatch({ type: API_GET_DATA_ITEM_SUCCESS, data: json.data })
      } else {
        dispatch({ type: API_DATA_ERROR, error: json.data })
      }
      dispatch({ type: API_END })
    })
    .catch(error => {
      dispatch({ type: API_END })
    });
}

export const dataDelete = (id) => (dispatch) => {
  dispatch({ type: API_START })
  const request = new Request(URL + 'data/' + id + '/delete', {
    headers: headers(dispatch)
  })
  fetch(request)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 'ok') {
        dispatch({ type: API_DATA_DELETE_SUCCESS, id: id })
      } else {
        dispatch({ type: API_DATA_ERROR, error: json.data })
      }
      dispatch({ type: API_END })
    })
    .catch(error => {
      dispatch({ type: API_END })
    });
}


export const dataPublish = (id) => (dispatch) => {
  dispatch({ type: API_START })
  const request = new Request(URL + 'data/' + id + '/publish', {
    headers: headers(dispatch)
  })
  fetch(request)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 'ok') {
        dispatch({ type: API_DATA_DELETE_SUCCESS, id: id })
      } else {
        dispatch({ type: API_DATA_ERROR, error: json.data })
      }
      dispatch({ type: API_END })
    })
    .catch(error => {
      dispatch({ type: API_END })
    });
}

export const imageCrop = (image) => (dispatch) => {
  dispatch({ type: IMAGE_CROP, image: image })
}

export const imageCropped = (pixelCrop) => (dispatch) => {
  dispatch({ type: IMAGE_CROPPED, pixelCrop: pixelCrop })
}

export const updateImageUrl = (url) => (dispatch) => {
  dispatch({ type: IMAGE_FROM_URL, url: url })
}